<template>
  <app-layout>
    <div class="w-full flex justify-center rounded-lg items-center md:w-5/12 md:mx-auto">
      <div class="w-11/12 my-10 p-6 bg-white bg-opacity-90 shadow-xl">
        <h1 class="font-bold text-lg md:text-xl xl:text-2xl text-green-1002 text-center bold">
          FAÇA SEU LOGIN
        </h1>
        <p class="text-center mt-2 text-green-1002 md:text-lg xl:text-xl">
          É um de nossos talentos e ainda não possui acesso?
          <router-link :to="{ name: 'register' }" class="font-bold text-green-1003 hover:underline">
            Clique aqui!
          </router-link>
        </p>
        <form @submit.prevent="submit">
          <input type="email" name="email" id="email" placeholder="E-MAIL"
            class="py-1 px-2 bg-green-1003 text-white placeholder-white w-full mt-6 text-lg focus:outline-none"
            v-model="email" autocomplete="email" />

          <input type="password" name="password" id="password" placeholder="SENHA"
            class="py-1 px-2 bg-green-1003 text-white placeholder-white w-full mt-3 text-lg focus:outline-none"
            v-model="password" autocomplete="current-password" />

          <router-link :to="{ name: 'recovery' }" class="w-full text-left text-green-1002 mt-3">
            Esqueci minha <span class="bold">senha</span>
          </router-link>

          <button type="submit"
            class="py-1 bg-green-1002 text-white text-center w-full mt-5 text-lg focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
            :disabled="submitting">
            {{ buttonText }}
          </button>
        </form>
        <div class="text-center mt-2 text-green-1002 md:text-lg xl:text-xl">
          <br />
          <span class="line">
            <h1 class="text-green-1002 text-center bold">
              CADASTRE SUA EMPRESA
            </h1>
          </span>
          <br />
          <p class="bold text-green-1002 md:text-lg xl:text-xl text-center">
            Caso você seja um empregador e não possui seu acesso
            <router-link :to="{ name: 'validate-access' }" class="bold text-green-1003 no-underline">
              Clique aqui!
            </router-link>
          </p>
          <br />
        </div>
      </div>
    </div>
    <div class="relative z-20" v-show="modal_confirm_email">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75"></div>
      <div class="fixed inset-4 z-30 transition transform">
        <div class="flex items-end justify-center p-4 text-center sm:items-center sm:p-0 h-full">
          <div
            class="absolute transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-2/5 h-5/6"
            style="height: auto;">
            <div class="flex flex-col p-6 h-full bg-white bg-opacity-80">
              <div class="flex justify-between">
                <h1 class="font-bold text-lg  text-green-700 text-center bold">
                  <i class="ri-mail-close-line text-red-400 mr-1"></i> Opps.. O seu e-mail ainda não foi confirmado...
                </h1>
                <button @click="modal_confirm_email = false" @keyup.esc="close()"><svg xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24" width="24" height="24">
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                  </svg></button>
              </div>
              <p class="text-lg text-justify px-2 mt-4">
                No momento do cadastro enviamos para você um e-mail com um link de ativação do seu acesso e o mesmo ainda
                não foi confirmado.
              </p>
              <p class="text-lg text-justify px-2 mt-4">
                Verifique na sua caixa de entrada ou na caixa de spam ou clique no botão abaixo para reenviar o link de
                ativação.
              </p>
              <div class="flex items-center justify-center">
                <button
                  class="flex bg-green-1002 text-white text-center my-3 p-2 px-5 rounded-md text-lg focus:outline-none"
                  type="button" @click="sendmail">
                  Reenviar e-mail <i class="ri-mail-send-fill ml-3"></i>
                </button>
              </div>
              <p class="text-lg px-2 mt-1">
                Se mesmo assim você não conseguir encontrar nosso e-mail, entre em contato com o Sindicato Rural da sua
                região.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-layout>
</template>

<script>
import AppLayout from '../layouts/AppLayout';
import axios from '@/utils/axios';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Login',

  title() {
    return `${process.env.VUE_APP_NAME} | Acessar`;
  },

  components: {
    AppLayout
  },

  data() {
    return {
      submitting: false,
      email: null,
      password: null,
      modal_confirm_email: false,
      user_reesend: null
    };
  },

  computed: {
    ...mapGetters('auth', {
      authenticated: 'authenticated',
      permissions: 'permissions'
    }),

    buttonText() {
      return this.submitting ? 'CARREGANDO...' : 'ENTRAR';
    },

    ...mapGetters('auth', {
      user: 'user'
    })
  },

  methods: {
    ...mapActions('auth', {
      login: 'login'
    }),

    submit() {
      this.submitting = true;

      this.login({
        email: this.email,
        password: this.password
      })
        .then(response => {
          this.$toast.success(response.message);

          if (
            this.user.role.name === 'participant' ||
            this.user.role.name === 'Instructor'
          ) {
            this.$router.push(
              this.$route.query.redirect ?? {
                name: 'dashboard'
              }
            );
          } else {
            this.$router.push(
              this.$route.query.redirect ?? {
                name: 'search'
              }
            );
          }
        })
        .catch(({ response }) => {
          if (response.data.email_confirmed == false) {
            this.modal_confirm_email = true;
            this.user_reesend = response.data.id_user;
          } else {
            this.$toast.error(response.data.message);
          }
        })
        .finally(() => (this.submitting = false));
    },

    sendmail() {
      axios
        .put(`/api/associados/resendmailconfirmation/${this.user_reesend}`)
        .then(response => {
          console.log(response.data);
          this.$toast.success(response.data.data.message);
          this.modal_confirm_email = false;
        });
    }
  }
};
</script>
<style scoped>
.line {
  display: flex;
  align-items: center;
}

.line::after,
.line::before {
  content: '';
  flex: 1;
  height: 2px;
  background-color: #357a6f;
  margin: 0 10px;
}
</style>
